import moment from 'moment';

jQuery( document ).ready(function($) {
    // events
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Emilia Mernes/events?app_id=45PRESS_Emilia',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert( 'Error fetching events!' ); 
    },
    success: data => {
    const events = $( '#events' );
    let html = '';
    console.log(data);
    if ( data.length ) {
      for ( let event of data ) {
        let region = event.venue.region?event.venue.region:event.venue.country;
        let location = event.venue.city + ', ' + region;
        let lineup = '';
        if ( location === ', ' ) {location = '';}
        if ( event.lineup.length > 1){
            lineup = event.lineup.filter((word) => word != 'Emilia Mernes')
            lineup = event.lineup.join(', ')
        }
        if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
        html += '<div class="event">';
        html += '<div class="event-date">' +moment( event.datetime ).format( 'MMM D, Y' ) + '</div>';
        html += '<div class="event-location">' + location + '</div>';
        html += '<div class="event-venue">' + event.venue.name + (lineup?'<span>' + lineup + '</span>':'') + '</div>';
        html += '<div class="event-links">';
        for ( let offer of event.offers ) {
          html += '<a href="' + offer.url + '" target="_blank" class="btn">' + offer.type + '</a>';
        }
        html += '</div>';
        html += '</div>';
      }
      events.html( html );
      } else {
        events.html( 'No upcoming events.' );
      }
    }
  });
    // var countDownDate = moment('2023-11-02 23:00 -0500', "YYYY-MM-DD HH:mm z");

    // // Update the count down every seconds
    // var x = setInterval(function() {

    //     var now = moment();
    //     var distance = countDownDate.diff(now);

    //     // Time calculations for days, hours, minutes and seconds
    //     var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //     var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //     var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //     var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    //     $(".days10").html(Math.floor(days/10));
    //     $(".days1").html(days%10);
    //     $(".hours10").html(Math.floor(hours/10));
    //     $(".hours1").html(hours%10);
    //     $(".minutes10").html(Math.floor(minutes/10));
    //     $(".minutes1").html(minutes%10);
    //     $(".seconds10").html(Math.floor(seconds/10));
    //     $(".seconds1").html(seconds%10);
        

    //     if (distance < 0) {
    //         clearInterval(x);
    //         $(".days10").html(0);
    //         $(".days1").html(0);
    //         $(".hours10").html(0);
    //         $(".hours1").html(0);
    //         $(".minutes10").html(0);
    //         $(".minutes1").html(0);
    //         $(".seconds10").html(0);
    //         $(".seconds1").html(0);
    //     }
    // }, 500);
});